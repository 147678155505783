import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title} || Virtual Clubhouse For Your Community</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "mtion.xyz | Virtual Clubhouse For Your Community!",
  keyword: "live streaming, game engine, video game, creator kit",
  desc: "mtion.xyz creates a no-code game engine for creators.",
};

export default Meta;
